import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'

import Investments from 'components/layout/Sections/Investments/Simple'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import type { PlannedInvestmentsPageQuery } from 'types/graphql'
import type { InvestmentCardSimple } from 'types/investments'

const PlannedInvestmentsPage: React.FC<PageProps<PlannedInvestmentsPageQuery>> =
  ({ data }) => {
    const PAGE = data?.page?.pageInvestmentsPreparation
    const INVESTMENTS = data?.investmentCategory?.investments?.nodes
    const PAGE_SEO = data?.page?.seo

    if (!PAGE || !PAGE_SEO || !INVESTMENTS)
      throw new Error("CMS data didn't load properly")

    const PLANNED_INVESTMENTS: InvestmentCardSimple[] = useMemo(
      () =>
        INVESTMENTS.map((inv) => ({
          image: {
            src: inv?.investmentFields?.investmentMapImg?.localFile
              ?.childImageSharp?.gatsbyImageData!,
            alt: inv?.investmentFields?.investmentMapImg?.altText!,
          },
          location: inv?.investmentFields?.investmentDistrict
            ? `${inv?.investmentFields?.investmentCity} - ${inv?.investmentFields?.investmentDistrict}`
            : inv?.investmentFields?.investmentCity!,
          name: inv?.title!,
          description: inv?.investmentFields?.investmentTeaser!,
          link: `/inwestycje-w-przygotowaniu/${inv?.slug}`,
          tag: {
            text: inv?.investmentFields?.investmentTagText!,
            color: inv?.investmentFields?.investmentTagColor!,
          },
        })) || [],
      []
    )

    return (
      <Layout background="white50">
        <SEO seo={PAGE_SEO} />
        <Breadcrumbs
          crumbs={[{ name: PAGE.investmentsPreparationPageS1Title! }]}
        />
        <main>
          <Investments
            title={PAGE.investmentsPreparationPageS1Title!}
            investments={PLANNED_INVESTMENTS}
          />
        </main>
      </Layout>
    )
  }

export default PlannedInvestmentsPage

export const query = graphql`
  query PlannedInvestmentsPage($locale: String) {
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/inwestycje-w-przygotowaniu/" }
    ) {
      seo {
        ...WpSEO
      }
      pageInvestmentsPreparation {
        investmentsPreparationPageS1Title
      }
    }
    investmentCategory: wpInvestmentCategory(
      slug: { eq: "inwestycja-zaplanowana" }
    ) {
      investments {
        nodes {
          slug
          title
          investmentFields {
            investmentCity
            investmentDistrict
            investmentTeaser
            investmentTagText
            investmentTagColor
            investmentMapImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
